<template>
  <l-default>
    <div :is="component" />
  </l-default>
</template>

<script>
import { mapGetters } from 'vuex'
import LDefault from '../layouts/LDefault'
import PageFreeCall from '@/pages/PageFreeCall.vue'
import Dashboard from '@/components/Dashboard.vue'

export default {
  name: 'PageHome',
  components: { LDefault },
  computed: {
    ...mapGetters(['isLoggedIn']),
    component () {
      return this.isLoggedIn ? Dashboard : PageFreeCall
    }
  }
}
</script>
